import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {
  getPageGridModel,
  IPageWithGridModel,
  PageWithGridComponent
} from '../../../oex-ui-kit/base/page-with-grid.component';
import {IAdminPermission, IAdminRole} from '../../services/api.types';
import {APIService} from '../../services/api.service';
import {GridComponent, IGridColumnDef, IGridConfig, IRowActionEvent} from '../../../oex-ui-kit/components/grid/grid';
import {NgSelectModule} from '@ng-select/ng-select';
import {DialogService} from '../../services/dialog.service';
import {ModalService} from '../../services/modal.service';
import {take} from 'rxjs';

const ROLE_PREFIX = '_role_';

export interface IAdminPermissionsModel extends IPageWithGridModel<IAdminPermission> {
  roles: IAdminRole[];
}

export const resolveAdminPermissions: ResolveFn<IAdminPermissionsModel> = async (route: ActivatedRouteSnapshot) => {
  const api = inject(APIService);
  const model: IAdminPermissionsModel = {
    roles: [],
    ...getPageGridModel<IAdminPermission>(route)
  };
  //FilterComponent.loadFilterValuesFromStorage(model.filters, st, 'csh-filters-permissions');

  try {
    const [permsResp, rolesResp] = await Promise.all([
      api.getAdminPermissionsList(model.sorting),
      api.getAdminRoles()
    ]);

    const perms = permsResp?.permission ?? [];
    const roles = rolesResp?.role ?? [];

    // Process data, add roles array as fields to work with grid
    perms.forEach((p: any) => {
      p.roles.forEach((r: string) => {
        p[ROLE_PREFIX + r] = true;
      });
    })

    model.data = {
      total: perms.length,
      pageSize: perms.length,
      currPage: 1,
      items: perms
    };
    model.roles = roles;
  } catch (error) {
    console.error("Failed to fetch permissions and roles:", error);
  }
  return model;
}

@Component({
  selector: 'st-admin-permissions-page',
  imports: [
    GridComponent,
    NgSelectModule
  ],
  templateUrl: './admin-permissions-page.component.html',
  styleUrl: './admin-permissions-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPermissionsPageComponent extends PageWithGridComponent<IAdminPermissionsModel> implements OnInit {
  private ds = inject(DialogService);
  private modal = inject(ModalService);
  private api = inject(APIService);

  override model!: IAdminPermissionsModel;
  columnsDef = signal<IGridColumnDef[]>([
    {
      text: 'Permission',
      field: 'name',
      //sorting: 'n',
      editable: true,
      initialSorting: 'asc'
    },
    {
      text: 'Description',
      field: 'description',
      editable: true,
      //sorting: 'd',
      initialSorting: 'asc',
      width: '20%'
    }
  ]);
  conf: IGridConfig = {
    sortingField: 'n',
    sortingOrder: 'asc',
    highlightChangedCells: true,
    rowActions: [{id: 'delete', 'icon': '/assets/img/icon-delete.svg', tooltip: 'Delete user'}]
  }
  protected override resolver = resolveAdminPermissions;

  override ngOnInit() {
    super.ngOnInit();
    this.data$.pipe(take(1)).subscribe(d => {
      this.buildRoleColumns(d.model.roles);
    });
  }

  async save(g: GridComponent) {
    const items = GridComponent.getChangedRecords(this.model.data.items);
    if (!items.length) {
      this.toggleEditMode();
      return;
    }
    this.preparePermsToSave(items);

    this.ps.show();
    try {
      await this.api.saveAdminPermissions(items);
      g.save();
      this.toggleEditMode();
      void this.refreshData();
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  onGridAction(event: IRowActionEvent<IAdminPermission>) {
    switch (event.action.id) {
      case 'delete':
        this.askForDeletion(event.data);
        break;
    }
  }

  addNewPermission() {
    if (!this.isEdit()) {
      this.toggleEditMode();
    }
    setTimeout(() => {
      this.grid()?.addNewRecord<IAdminPermission>({
        id: undefined,
        name: '',
        description: '',
        roles: [],
      }, true);
    });
  }

  /*  hasRole(row: IAdminPermission, col: IGridColumnDef) {
      return row.roles.includes(col.userData);
    }*/

  // Move roles flags from fields to `roles` array in permission. Because grid uses fields to set tru/false for role
  private preparePermsToSave(perms: IAdminPermission[]) {
    perms.forEach(p => {
      p.roles = [];
      Object.keys(p).filter(k => k.startsWith(ROLE_PREFIX)).forEach(roleKey => {
        if ((p as any)[roleKey]) {
          p.roles.push(roleKey.slice(ROLE_PREFIX.length));
        }
      })
    });
  }

  private buildRoleColumns(roles: IAdminRole[]) {
    this.columnsDef.update(colsDef => [
      ...colsDef,
      ...roles.map(r => ({
        text: r.name,
        field: ROLE_PREFIX + r.slug,
        editable: true,
        editorType: 'checkbox',
        template: 'checkbox',
        userData: r.slug
      } as IGridColumnDef))
    ]);
  }

  private askForDeletion(perm: IAdminPermission) {
    void this.ds.showConfirmDialog(`Are you sure you want to delete "${perm.name}" permission?`, async () => {
      void this.deletePermission(perm);
    });
  }

  private async deletePermission(perm: IAdminPermission) {
    if (!perm.id) {
      this.removeFromGrid(perm);
      return;
    }
    this.ps.show();
    try {
      await this.api.deleteAdminPermission(perm.id);
      this.removeFromGrid(perm);
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }

  private removeFromGrid(perm: IAdminPermission) {
    const idx = this.model.data.items.indexOf(perm);
    if (idx !== -1) {
      this.model.data.items.splice(idx, 1);
      // TODO: rewrite model to signal
      this.grid()?.detectChanges();
    }
  }
}
